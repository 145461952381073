import React from 'react';
import Box from '../Box';
import {FlexCol} from '../Flex';
import {shade} from '@theme-ui/color';

/**
 * Empty States are designed to onboard users and helping them to "fill" the
 * empty space (e.g. by uploading images or adding highlights). They should contain some sort of call to action, when possible.
 */
const EmptyState = React.forwardRef(({
    children,
    ...restProps
}, ref) => {
    return (
        <Box ref={ref} border={1} borderColor={shade('border', 0.25)} borderRadius="md" borderStyle="dashed" p={['sm', 'md']} textAlign="center" {...restProps}>
            <FlexCol>
                {children}
            </FlexCol>
        </Box>
    );
});

export default EmptyState;
