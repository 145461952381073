

import React from 'react';

const createComponent = (displayName, Component, props) => {

    const MdxComponent = ({children, ...restProps}) => {
        const componentProps = typeof props === 'function'
            ? props(restProps)
            : props;

        return (
            <Component {...componentProps}>
                {children}
            </Component>
        );
    };

    MdxComponent.displayName = `createComponent(${displayName})`;

    return MdxComponent;
};

export default createComponent;
