import React, {useState} from 'react'; // eslint-disable-line no-unused-vars
import Box from '../Box';
import IconButton from '../IconButton';
import Input from '../Input';

import {IconVisibilityOn, IconVisibilityOff} from '../../icons';

/**
 * renders a Password Field based on our input component with a toggle to show or
 * hide the current typed password
 */
const Password = React.forwardRef((props, ref) => {
    const {...restProps} = props;
    const [state, setState] = useState({
        isVisible: false
    });

    const toggleVisible = () => {
        setState({
            ...state,
            isVisible: !state.isVisible
        });
    };
    return (
        <Input
            ref={ref}
            type={state.isVisible ? 'text' : 'password'}
            {...restProps}
            overlay={(
                <Box p="xxs">
                    <IconButton
                        icon={state.isVisible ? IconVisibilityOff : IconVisibilityOn}
                        variant="ghost"
                        tabIndex="-1"
                        onClick={toggleVisible}
                    />
                </Box>
            )}
        />
    );
});

export default Password;
