import React from 'react';

// Components
import Menu from '../Menu';
import {Badge, Button, FlexRow, Text} from '../../../ui/components';
import {IconArticle, IconHome, IconTag} from '../../../ui/icons';

const MdxHeader = () => {
    return (
        <FlexRow
            width="100%"
            maxWidth="6xl"
            alignX="stretch"
            alignY="center"
        >
            <FlexRow
                alignX="left"
                alignY="center"
                space="xs"
                sx={{
                    display: ['inline-flex', 'none']
                }}
            >
                <Menu
                    alignY="bottom"
                    color="textOnHeader"
                    menuColor="textOnHeader"
                    menuBg="header"
                    showLabel={true}
                />
            </FlexRow>
            <FlexRow
                alignX="left"
                alignY="center"
                space="xs"
                sx={{
                    display: ['none', 'inline-flex']
                }}
            >
                <Button
                    variant="ghost"
                    color="textOnHeader"
                    pill={true}
                    size="sm+"
                    icon={IconHome}
                    href="/"
                >
                    {'Startseite'}
                </Button>
                <Button
                    variant="ghost"
                    color="textOnHeader"
                    pill={true}
                    size="sm+"
                    icon={IconArticle}
                    href="/page/1/"
                >
                    {'Artikel'}
                </Button>
                <Button
                    variant="ghost"
                    color="textOnHeader"
                    pill={true}
                    size="sm+"
                    icon={IconTag}
                    href="/tags/"
                >
                    {'Kategorien'}
                </Button>
            </FlexRow>

            <Badge
                bg="footer"
                color="textOnFooter"
                sx={{
                    display: ['inline-flex', 'none', 'inline-flex']
                }}
            >
                #StayWithUkraine
            </Badge>
        </FlexRow>
    );
};

export default MdxHeader;
