import React, {useState} from 'react';
import ReactDOM from 'react-dom';

// Utils
import {useIsomorphicLayoutEffect} from '../../../hooks';

// Components
import {MDXProvider} from '@mdx-js/react';
import {Box, Heading} from '../../../ui/components';

const MdxComments = ({children}) => {
    const [commentsContainer, setCommentsContainer] = useState(null);

    useIsomorphicLayoutEffect(() => {
        const commentsContainer = document.getElementById('comments');

        if (commentsContainer) {
            // Make container visible
            commentsContainer.style.display='flex';

            // Trigger render with visible container
            setCommentsContainer(commentsContainer);

            return () => {
                // Hide container
                commentsContainer.style.display = null;
            };
        }
    }, []);

    return commentsContainer && ReactDOM.createPortal((
        <Box
            display="flex"
            width="100%"
            maxWidth="xl"
            alignItems="center"
            justifyContent="start"
            flexDirection="column"
            sx={{gap: 'md'}}
        >
            <Heading as="h2" level={3} color="textOnBackground">
                Kommentare
            </Heading>
            <MDXProvider components={children}>
                {children}
            </MDXProvider>
        </Box>
    ), commentsContainer);
};

export default MdxComments;
