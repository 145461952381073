import React from 'react';

// Utils
import {createComponent, getPropsByHref} from '../../utils';

// Components
import {MDXProvider} from '@mdx-js/react';
import {Box, Bullet, BulletList, Card, Heading, Text, TextLink} from '../../../ui/components';
import {alpha} from '@theme-ui/color';

const components = {
    h1: createComponent('h1', Heading, {
        as: 'h1',
        level: 2,
        pt: 'xs',
        pb: 'sm',
        sx: {
            ':first-of-type': {
                pt: 'none'
            }
        }
    }),
    h2: createComponent('h2', Heading, {
        as: 'h2',
        level: 3,
        pt: 'xs',
        pb: 'sm',
        sx: {
            ':first-of-type': {
                pt: 'none'
            }
        }
    }),
    h3: createComponent('h3', Heading, {
        as: 'h3',
        level: 4,
        pt: 'xs',
        pb: 'sm',
        sx: {
            ':first-of-type': {
                pt: 'none'
            }
        }
    }),
    h4: createComponent('h4', Heading, {
        as: 'h4',
        level: 5,
        pt: 'xs',
        pb: 'sm',
        sx: {
            ':first-of-type': {
                pt: 'none'
            }
        }
    }),
    p: createComponent('p', Text, {
        size: [2, 2, 2, 3],
        lineHeight: '2rem',
        pb: 'sm',
        sx: {
            ':last-child': {
                pb: 'none'
            }
        }
    }),
    a: createComponent('a', TextLink, ({href}) => ({
        href,
        decoration: 'inverted',
        ...getPropsByHref(href)
    })),
    ul: createComponent('ul', BulletList, {
        sx: {
            mb: 'sm',
            '* ul': {
                mb: 'none'
            }
        }
    }),
    li: createComponent('li', Bullet, {
        size: [2, 2, 2, 3]
    }),
    em: createComponent('em', Box, {
        as: 'em',
        sx: {
            fontStyle: 'italic'
        }
    }),
    strong: createComponent('strong', Box, {
        as: 'strong',
        sx: {
            fontWeight: 'bold'
        }
    }),
    blockquote: createComponent('blockquote', Box, {
        as: 'blockquote',
        px: ['sm', 'md', 'lg'],
        pb: 'sm',
        sx: {
            fontStyle: 'italic',
            ':last-child': {
                pb: 'none'
            }
        }
    }),
    table: createComponent('table', Box, {
        as: 'table',
        mb: 'sm',
        sx: {
            width: '100%',
            borderSpacing: '0',
            borderCollapse: 'collapse',
            ':last-child': {
                mb: 'none'
            }
        }
    }),
    thead: createComponent('thead', Box, {
        as: 'thead',
        sx: {
            fontWeight: 'bold',
            textAlign: 'left',
            bg: alpha('grey', 0.1)
        }
    }),
    tr: createComponent('tr', Box, {
        as: 'tr',
        sx: {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: alpha('grey', 0.2)
        }
    }),
    th: createComponent('th', Box, {
        as: 'th',
        p: 'sm'
    }),
    tbody: createComponent('tbody', Box, {
        as: 'tbody',
        sx: {
            fontWeight: 'normal',
            textAlign: 'left',
            '> :nth-child(even)': {
                bg: alpha('grey', 0.1)
            }
        }
    }),
    td: createComponent('td', Box, {
        as: 'td',
        p: 'sm'
    })

};

const MdxCard = ({children, ...props}) => {
    return (
        <Card p={['sm', 'md', 'lg']} width="100%" {...props}>
            <MDXProvider components={components}>
                {children}
            </MDXProvider>
        </Card>
    );
};

export default MdxCard;
